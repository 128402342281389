import './App.css';
import React, { useState } from 'react';
import { Link } from "react-router-dom";

//0x813EB5844a2398AdCd403E52bCF2c83Be2F0De0e


function Page() {
  const [url, setUrl] = useState("");
  return (
    <>
    <form>
 <input type="text" value={url} placeholder="Search an ethereum address or ens" onChange={(e) => setUrl(e.target.value)} />
 <Link to={`/${url}`} >
          <button className="search-button" type="submit">Search</button>
        </Link>
        </form>
    </>
  );
}

export default Page;