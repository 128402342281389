import './App.css';
import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { NftGallery } from 'react-nft-gallery';
import MiniSearch from "./MiniSearch.js"
import Logo from './logo.svg'


function Page() {
    const location = useLocation()
  return (
      <>
      <Link to="/">
        <img alt="" className="logo-image" src={Logo}/>
        </Link>
    <div className="App">
        <MiniSearch/>
    <h1>    {location.pathname &&
        `${location.pathname.slice(1, 6)}...${location.pathname.slice(
          location.pathname.length - 4,
          location.pathname.length
        )}`}</h1>
    <NftGallery ownerAddress={location.pathname.slice(1)} />
    </div>
    </>
  );
}

export default Page;