import React from 'react'
import Search from './Search'
import Logo from './logo.svg'


function SearchPage() {
    return (
        <>
            <img alt=""className="logo-image" src={Logo}/>
        <div className="search-page">
            <div>
            <Search/>
            </div>
            {/* <span>copyleft yagmi © 2021</span> */}
        </div>
        </>
    )
}

export default SearchPage
