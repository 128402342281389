import './App.css';
import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";

//0x813EB5844a2398AdCd403E52bCF2c83Be2F0De0e

function Page() {
  const [url, setUrl] = useState("");
  function refreshPage(){ 
    setTimeout(()=>{
        window.location.reload(false);
    }, 500);
    console.log('page to reload')
}
const location = useLocation()
  return (
    <>
    <form>
 <input type="text" placeholder={location.pathname.slice(1)} onChange={(e) => setUrl(e.target.value)} />
 <Link to={`/${url}`} >
          <button className="search-button" type="submit" onClick={ refreshPage }>Search</button>
        </Link>
        </form>
    </>
  );
}

export default Page;