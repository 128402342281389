import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Page from "./Page"
import SearchPage from "./SearchPage"


function App() {
  return (
    <>
        <Router>
          <Switch>
            <Route path="/" exact>
              <SearchPage />
            </Route>
              <Route path="/:hotelid" exactly component={Page} />
          </Switch>
        </Router>
    </>
  );
}

export default App;
